/* Gotham */

@font-face {
  font-family: "Gotham";
  font-weight: 300;
  src: url(../public/assets/fonts/Gotham-Light.otf);
}
@font-face {
  font-family: "Gotham";
  font-weight: 200;
  src: url(../public/assets/fonts/Gotham-XLight.otf);
}

@font-face {
  font-family: "Gotham";
  font-weight: 100;
  src: url(../public/assets/fonts/Gotham-Thin.otf);
}

* {
  margin: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  scroll-padding-top: 95px;
}

a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}
#home {
  scroll-margin-top: 50px;
}

.no-outline:focus {
  outline: none;
}
